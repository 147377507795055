import createChoices from './createChoices'

const hiddenPlaceholderClassName = "placeholder--hidden";

const setupChoicesSelect = (element) => {
  if (!element) return;
  const choicesElement = createChoices(element);

  // for multiselect, choices doesn't hide the placeholder text when an option has been selected
  // so we toggle it manually https://github.com/Choices-js/Choices/issues/770
  element.addEventListener("change", () => {
    const input = element.parentElement.querySelector(".choices__input.choices__input--cloned");

    if (!input) return;

    if (choicesElement.itemList.element.children.length) {
      input.classList.add(hiddenPlaceholderClassName);
    } else {
      input.classList.remove(hiddenPlaceholderClassName);
    }
  });
}

document.addEventListener('turbolinks:load', function (event) {
  const multiSelects = Array.from(document.getElementsByClassName('select-multiple'));
  multiSelects.forEach(setupChoicesSelect);
})
