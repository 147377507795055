import bulmaSteps from 'bulma-extensions/bulma-steps/dist/js/bulma-steps';

document.addEventListener('turbolinks:load', function (event) {
  const element = document.getElementById('questionnaire-steps');
  if (!element) return;
  let steps = new bulmaSteps('#questionnaire-steps', {
    onShow: function (step_id) {
      scroll(0, 0)
    }
  })
})
