import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.min.js';
import ahoy from "ahoy.js";
ahoy.configure({cookies: false});

// Initialize all input of date type.
const calendars = bulmaCalendar.attach('[type="date"]', {});

// Loop on each calendar initialized
calendars.forEach(calendar => {
	// Add listener to date:selected event
	calendar.on('date:selected', date => {
		console.log(date);
	});
});

// To access to bulmaCalendar instance of an element
const element = document.querySelector('.publish_date');
if (element) {
	// bulmaCalendar instance is available as element.bulmaCalendar
	element.bulmaCalendar.on('select', datepicker => {
		console.log(datepicker.data.value());
	});
}
