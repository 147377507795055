/* eslint no-console:0 */

// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")
require('../src/javascripts/nested_forms/addFields')
require('../src/javascripts/nested_forms/removeFields')

// Custom JS
import '../src/javascripts/profiles.js';
import '../src/javascripts/navigation.js';
import '../src/javascripts/posts.js';
import '../src/javascripts/questionnaire.js';


document.addEventListener('turbolinks:load', function (event) {
  for (let form of document.querySelectorAll('form[method=get][data-remote=true]')) {
    if (form.id === 'js-turbolinks-get-form') {
      form.addEventListener('ajax:beforeSend', function (event) {
        const detail = event.detail,
          xhr = detail[0], options = detail[1];

        Turbolinks.visit(options.url);
        event.preventDefault();
      });
    }
  }
});
