const Choices = require('choices.js')

export default function createChoices(element) {
  return new Choices(element, {
    fuseOptions: { threshold: 0.0 },
    removeItemButton: true,
    noChoicesText: 'Keine Optionen mehr zur Auswahl',
    itemSelectText: 'Drücken um auszuwählen'
  });
}

